.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10000000;
}

.button-container {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
}

.icon-button {
    background: none;
    border: none;
    margin: 5px;
    cursor: pointer;
}

.icon-button img {
    width: 30px;
    height: 30px;
}

.icon-button:hover img {
    filter: brightness(1.2);
}

.summary-modal {
    position: absolute;
    top: 300px;
    right: 10px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: none;
    z-index: 10000000;
}

.summary-modal.visible {
    display: block;
}
