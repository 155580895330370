.app-root {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
}

.app-header {
  height: 5vh;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: white;
}

.app-body {
  display: block;
  height: 95vh;
}

.header-bar {
  padding-left: 10px;
}